/* eslint-disable */
/**
 * Created by steve.gibbings on 05/07/2016.
 */

import 'core-js/stable';
import 'regenerator-runtime/runtime';
import 'classlist-polyfill';
import 'formdata-polyfill';
import 'whatwg-fetch';

// TODO: Remove deprecated element-dataset polyfill
require('element-dataset')();
